import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import brittany from "../images/brittany-kleszynski-dvm-veterinary-research.jpg"
import {
  faDog,
  faNotesMedical,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fade from "react-reveal/Fade"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="Welcome to Pawprint Communications. Veterinary and medical writing for professionals. Pet education, research, and professional materials are a few of the services we provide. Brittany Kleszynski, DVM"
    />
    <div className="hero">
      <Fade bottom cascade>
        <div className="container p-0 text-center">
          <div className="hero-text-heading">WELCOME TO PAWPRINT</div>
          <div className="callout text-center">Writing That Speaks</div>
        </div>
      </Fade>
    </div>
    <Fade bottom cascade>
      <div className="container py-5">
        <div className="row text-center standout">
          <div className="col-md-4 py-5 ">
            <Link to="/services/" className="grow">
              <FontAwesomeIcon className="standoutIcon p-2" icon={faDog} />
              Pet Education
            </Link>
            <small>
              <br />
              Equipping pet parents and veterinary professionals
with medical content designed to educate and inspire
            </small>
          </div>
          <div className="col-md-4 py-5">
            <Link to="/services/" className="grow">
              <FontAwesomeIcon
                className="standoutIcon p-2"
                icon={faNotesMedical}
              />
              Medical News
            </Link>
            <small>
              <br />
              Educating the community through intriguing health writing
              characterized by superior clarity and accuracy
            </small>
          </div>
          <div className="col-md-4 py-5">
            <Link to="/services/" className="grow">
              <FontAwesomeIcon
                className="standoutIcon p-2"
                icon={faBriefcase}
              />
              Professional Materials
            </Link>
            <small>
              <br />
              Motivating healthcare professionals to achieve their fullest
              potential through high-quality continuing education
            </small>
          </div>
        </div>
      </div>
    </Fade>
    <div className="bg-light py-5">
      <Fade bottom>
        {/* <h2 className="pt-5 px-5 text-center">About the Doctor</h2> */}
      </Fade>
      <div className="container bg-light pt-5">
        <div className="row">
        <Fade bottom>

          <div className="col-md-3">
              <img
                className="headshot mb-5"
                src={brittany}
                alt="brittany kleszynski headshot"
              />
  </div><div className="col-md-9">
              <p>
              Throughout my experience in veterinary medicine and research, I have learned the importance of
clear, effective communication. It makes all the difference when caring for a patient and building
trust with a pet parent or working alongside a team to complete a task. I draw from these
experiences when creating intriguing health content for my clients.
              </p>
              <p>
              I have always enjoyed writing and educating others, so realizing I could merge the two of these
interests with my veterinary background was an exciting opportunity for me. That's when I created
Pawprint Communications LLC. Through this business, my goal is to be a source of credible
information that consumers and professionals can feel confident in.
              </p>
              <p>My writing philosophy is to convey and deliver the specific topic in a meaningful way that readers
can appreciate and understand. I am dedicated to helping my clients achieve their goals through
accurate and engaging medical content that speaks directly to their intended audiences.</p>
              <p>
                I currently serve clients in both <b>Animal</b> and <b>Human Health</b> and look forward to the opportunity to
work with you!
                
               
              </p>
    
              <p className="primary_color font-weight-bold">
                Brittany Kleszynski, DVM
                <span className="font-weight-normal">
                  {" "}
                  - Veterinary &amp; Medical Writer/Editor
                </span>
              </p>

          </div>
          </Fade>

        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
